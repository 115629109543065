import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'members',
    canActivate: [AuthGuard],    
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'members/agenda-view',
    loadChildren: () => import('./agenda-view/agenda-view.module').then( m => m.AgendaViewPageModule)
  },
  {
    path: 'members/agenda-view-client',
    loadChildren: () => import('./agenda-view-client/agenda-view-client.module').then( m => m.AgendaViewClientPageModule)
  },
  {
    path: 'members/agenda-view-client-contact',
    loadChildren: () => import('./agenda-view-client-contact/agenda-view-client-contact.module').then( m => m.AgendaViewClientContactPageModule)
  },
  {
    path: 'members/agenda-list-new',
    loadChildren: () => import('./agenda-list-new/agenda-list-new.module').then( m => m.AgendaListNewPageModule)
  },
  {
    path: 'members/agenda-list-new-search',
    loadChildren: () => import('./agenda-list-new-search/agenda-list-new-search.module').then( m => m.AgendaListNewSearchPageModule)
  },
  {
    path: 'members/agenda-execute',
    loadChildren: () => import('./agenda-execute/agenda-execute.module').then( m => m.AgendaExecutePageModule)
  },
  {
    path: 'members/agenda-client-new',
    loadChildren: () => import('./agenda-client-new/agenda-client-new.module').then( m => m.AgendaClientNewPageModule)
  },
  {
    path: 'members/agenda-view-complete-tracking',
    loadChildren: () => import('./agenda-view-complete-tracking/agenda-view-complete-tracking.module').then( m => m.AgendaViewCompleteTrackingPageModule)
  },
  {
    path: 'members/agenda-list-edit',
    loadChildren: () => import('./agenda-list-edit/agenda-list-edit.module').then( m => m.AgendaListEditPageModule)
  },
  {
    path: 'members/agenda-location-add',
    loadChildren: () => import('./agenda-location-add/agenda-location-add.module').then( m => m.AgendaLocationAddPageModule)
  },
  {
    path: 'members/agenda-client-new-map',
    loadChildren: () => import('./agenda-client-new-map/agenda-client-new-map.module').then( m => m.AgendaClientNewMapPageModule)
  },










];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
